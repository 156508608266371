import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Block } from '@mui/icons-material';
import Meta from 'src/components/Meta';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useGaEventPageView } from '../../utils/gaEvent';
import { Poppins, Spacer } from 'src/common';
import { useMutation, queryCache } from 'react-query';
import { workspaceJoinRequest, workspaceJoinRequestCancel } from 'src/api/workspace';
import Button from 'src/components/form/Button';
import { SimpleModal } from 'src/components/modals';
import { useAuth } from 'src/state/auth';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import useWorkspaceSwitch from 'src/hooks/useWorkspaceSwitch';

type WorkspaceAction =
  | 'WORKSPACE_OPEN'
  | 'WORKSPACE_ACCEPT_INVITE'
  | 'WORKSPACE_ACCESS_CANCEL'
  | 'WORKSPACE_ACCESS_REQUEST';

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
`;

const CenteredMessage = styled.div`
  text-align: center;
`;

const statusMessage: { [key: number]: string } = {
  404: 'That item does not exist.',
  403: 'You do not have permission to access this content.',
};

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const status = parseInt(queryParams.status as string) || 403;
  const workspaceId = queryParams.workspace_id as string;
  const workspaceName = queryParams.workspace_name as string;
  const redirectUrl = queryParams.redirect_url as string;

  useGaEventPageView(`Cydea | ${status || 'Error'}`);

  const [err, setErr] = useState('');

  const { user, userPermissions, isOrganisationAdmin } = useAuth();
  const { switchOrg, isLoading: isSwitchLoading } = useWorkspaceSwitch({ reloleadWindowOnSuccess: false });

  const resetCache = () => {
    queryCache.invalidateQueries();
  };

  const [reqJoin, { isLoading: isLoadingReqJoin }] = useMutation(workspaceJoinRequest, {
    onMutate: () => setErr(''),
    onSuccess: () => {
      resetCache();
    },
    onError: (err: any) => {
      setErr(err?.message);
    },
  });

  const [cancel, { isLoading: isLoadingCancel }] = useMutation(workspaceJoinRequestCancel, {
    onMutate: () => setErr(''),
    onSuccess: () => resetCache(),
    onError: (err: any) => {
      setErr(err?.message);
    },
  });

  const [action, setAction] = useState<WorkspaceAction | null>(null);

  useEffect(() => {
    const determineAction = (): WorkspaceAction | null => {
      if (!workspaceId) return null;

      if (userPermissions.app.platform.admin || isOrganisationAdmin) return 'WORKSPACE_OPEN';

      if (user?.invites.some((el) => el.id === workspaceId)) return 'WORKSPACE_ACCEPT_INVITE';

      if (user?.requests.some((request) => request.id === workspaceId)) return 'WORKSPACE_ACCESS_CANCEL';

      return 'WORKSPACE_ACCESS_REQUEST';
    };

    setAction(determineAction());
  }, [workspaceId, userPermissions, isOrganisationAdmin, user]);

  const handleRequestAccess = () => {
    if (workspaceId) {
      reqJoin(workspaceId);
      mpEvent(MPEvents.ButtonClick, { button: 'Request access' });
    }
  };

  const handleCancelRequest = () => {
    if (workspaceId) {
      cancel(workspaceId);
      mpEvent(MPEvents.ButtonClick, { button: 'Cancel request' });
    }
  };

  const handleSwitchWorkspace = () => {
    if (workspaceId) {
      switchOrg({ workspaceId, redirectUrl });
    }
  };

  const handleReturn = () => {
    window.location.href = '/risk-assessments';
  };

  const renderButton = () => {
    switch (action) {
      case 'WORKSPACE_ACCESS_REQUEST':
        return (
          <Button primary onClick={handleRequestAccess} disabled={isLoadingReqJoin}>
            REQUEST ACCESS
          </Button>
        );
      case 'WORKSPACE_ACCESS_CANCEL':
        return (
          <Button primary onClick={handleCancelRequest} disabled={isLoadingCancel}>
            CANCEL REQUEST
          </Button>
        );
      default:
        return null;
    }
  };

  const customMessage =
    status === 409 && workspaceName ? (
      <CenteredMessage>
        You are about to switch to workspace: {workspaceName}.
        <br />
        Would you like to continue?
      </CenteredMessage>
    ) : workspaceId ? (
      'You do not have access to this workspace.'
    ) : (
      statusMessage[status as 404 | 403] || 'An error occurred'
    );

  return (
    <>
      {err && <SimpleModal content={<span>{err}</span>} onClose={() => setErr('')} onOk={() => setErr('')} />}
      <Meta title={`Cydea | ${status || 'Error'}`} />
      <EmptyContainer>
        <Poppins color="prussianBlue" px={32} weight={600}>
          {status === 409 ? 'Are you sure?' : 'Permission Required'}
        </Poppins>
        <Spacer $px={10} />
        <Poppins color="davysGrey" px={16} weight={400}>
          {customMessage}
        </Poppins>
        <Spacer $px={15} />
        <ButtonContainer>
          <Button secondary onClick={handleReturn}>
            RETURN
          </Button>
          {status === 409 ? (
            <Button primary onClick={handleSwitchWorkspace} disabled={isSwitchLoading}>
              CONTINUE
            </Button>
          ) : (
            workspaceId && renderButton()
          )}
        </ButtonContainer>
      </EmptyContainer>
    </>
  );
};

export default ErrorPage;
